import styled from 'styled-components'
import React from 'react'
import Navbar from '../components/Navbar'
import { breakpoints, colors, layout } from '../theme'
import Footer from '../components/Footer'
import PolygonBanner from '../components/PolygonBanner'
import ParallaxShape from '../components/ParallaxShape'
import { Button, StyledButton } from '@monbanquet/crumble'
import { ParallaxProvider } from 'react-scroll-parallax'
import Helmet from 'react-helmet'

const PressePage = () => {
  return (
    <StyledPressePage>
      <Helmet
        title="Traiteur artisanal pour vos événements d'entreprise • Monbanquet.fr"
        meta={[
          {
            name: 'description',
            content:
              'Monbanquet.fr est le service traiteur événementiel qui valorise le savoir‑faire artisanal à Paris et à Lyon. Créez des moments en entreprise au meilleur prix !',
          },
        ]}
      />
      <Navbar />
      <div className="page-content">
        <ParallaxProvider>
          <PolygonBanner
            picture={{
              title: 'Presse',
              fluid: {
                src: '/images/IMG_2283.jpg',
                srcSet: '/images/IMG_2283.jpg',
                aspectRatio: 1,
                sizes: '100',
              },
            }}
            color="#F0E4D2"
          >
            <ParallaxShape
              x={5}
              y={5}
              shapeStyle={{
                height: '30px',
                width: '30px',
                borderRadius: '50%',
                border: '10px solid #0F7762',
              }}
            />
            <ParallaxShape
              x={75}
              y={5}
              shapeStyle={{
                height: '24px',
                width: '24px',
                borderRadius: '50%',
                border: '12px solid #0F7762',
              }}
            />
            <ParallaxShape
              x={55}
              y={45}
              shapeStyle={{
                width: '35px',
                height: '19px',
                border: '10px solid #030101',
                borderBottom: 'none',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
                transform: 'matrix(-0.87, 0.48, -0.48, -0.87, 0, 0)',
              }}
            />
            <ParallaxShape
              x={50}
              y={-10}
              shapeStyle={{
                width: '26px',
                height: '33px',
                top: '30%',
                left: '-40%',
                background: '#BC8046 0% 0% no-repeat padding-box',
                clipPath: 'polygon(0% 0%, 25% 0%, 100% 100%, 75% 100%)',
              }}
            />
            <div className="header">
              <h1>Presse</h1>
              <div className="press_description">
                Envie de parler de Monbanquet ?
                <p>
                  Retrouvez dans notre kit toutes les ressources nécessaires.
                </p>
              </div>
              <div className="banner-action">
                <Button
                  onClick={() =>
                    window.open(
                      'https://assets.monbanquet.fr/Press-kit_Monbanquet.zip',
                    )
                  }
                >
                  Télécharger notre kit presse
                </Button>
              </div>
            </div>
          </PolygonBanner>
        </ParallaxProvider>
        <div className="wrapper-presse">
          <h3>À propos de Monbanquet</h3>
          <p>
            <strong>
              Monbanquet c’est la solution traiteur B2B qui valorise le
              savoir-faire des artisans locaux.
            </strong>
          </p>
          <p>
            Créée en janvier 2016 par Maxime Renault, Mario Matar et Hugo
            Rivière, Monbanquet a déjà servi plus de 500 000 convives
          </p>
          <p>
            sur 10 000 événements. La startup qui travaille aujourd’hui avec
            plus de 70 artisans sélectionnés pour leur exigence et leur
          </p>
          <p>
            complémentarité, se présente aujourd’hui comme un des leaders
            français du service traiteur dédié aux entreprises.
          </p>
          <h3>Communiqués de presse</h3>
          <a
            className="press_title"
            href="https://assets.monbanquet.fr/Communiqué_de_Presse_Monbanquet_x_B2BFoodGroup_fevrier2020.pdf"
            target="_blank"
            rel="noopener noreferrer"
            title="Télécharger le PDF"
          >
            B2B Food Group annonce l’acquisition de Monbanquet afin de devenir
            le leader mondial du bien-manger en entreprise.
          </a>
          <p className="press_detail">18 février 2020</p>

          <a
            className="press_title"
            href="https://assets.monbanquet.fr/CP_Monbanquet_Vivatech_VF.pdf"
            target="_blank"
            rel="noopener noreferrer"
            title="Télécharger le PDF"
          >
            Monbanquet.fr, le nouveau traiteur qui casse les codes du traiteur
            événementiel et participe à l’économie solidaire.
          </a>
          <p className="press_detail">24 mai 2018</p>
          <h3>Contact</h3>
          <p>• Maxime Renault</p>
          <p>• maxime@monbanquet.fr</p>
          <p>• +33 (0)764076436</p>
        </div>
      </div>
      <Footer />
    </StyledPressePage>
  )
}

const StyledPressePage = styled.section`
  .page-content {
    margin-top: ${layout.navbarHeight.normal}px;
  }
  strong {
    font-weight: bold;
  }
  h1 {
    font-size: 60px;
    height: 70px;
    color: #bc8046;
    margin-bottom: 10px;
  }
  h3 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .press_description {
    margin-top: 20px;
    font-size: 24px;
    margin-bottom: 20px;
  }
  .banner-action {
    display: flex;
    button {
      z-index: 1;
    }
  }
  ${StyledButton} {
    margin-top: 30px;
    border-radius: 100px;
    background-color: ${colors.secondary.yellow};
    color: ${colors.brand.black};
    height: 66px;
    font-size: 19px;
    padding: 22px 64px;
    text-transform: none;
  }
  @media (max-width: ${breakpoints.desktop}px) {
    .children-content {
      text-align: center;
      .banner-action {
        display: block;
      }
      h1 {
        font-size: 35px;
        line-height: 40px;
      }
      h3 {
        font-size: 25px;
        font-weight: normal;
      }
    }
    ${StyledButton} {
      margin-top: 15px;
      padding: 0 26px;
    }
  }
  @media (max-width: ${breakpoints.mobile}px) {
    .page-content {
      margin-top: ${layout.navbarHeight.mobile}px;
    }
  }

  .wrapper-presse {
    font-size: 18px;
    max-width: ${layout.maxWidth}px;
    width: ${layout.width};
    margin: 0 auto;
    margin-bottom: 50px;
    .press_title:before {
      content: '• ';
    }
    .press_title {
      font-weight: 600;
      text-decoration: underline;
    }
    a.press_title:hover {
      color: #bc8046;
    }
    p.press_detail {
      padding-left: 10px;
      opacity: 0.6;
    }
    h3 {
      color: ${colors.brand.normal};
      padding: 5px 0;
      border-radius: 50px;
      margin-top: 50px;
      margin-bottom: 15px;
      font-size: 1.2rem;
    }
    p {
      margin: 10px 0;
    }
  }
  @media (max-width: ${breakpoints.tablet}px) {
    .wrapper {
      padding: 0;
    }
  }
`

export default PressePage
