import React from 'react'
import styled from 'styled-components'
import { breakpoints } from '../theme'
import Img from 'gatsby-image'
import LogoStamp from '../assets/logo-stamp.svg'
import GoogleRating from './GoogleRating'
import Link from '../components/Link'
import { StyledParallaxShape } from '../components/ParallaxShape'

const PolygonBanner = ({ color, picture, withStampLogo = true, children }) => (
  <StyledPolygonBanner color={color}>
    {withStampLogo && <img src={LogoStamp} className="logo-stamp" alt="" />}
    <div className="content">
      <div className="children-content">{children}</div>
    </div>
    <div className="picture">
      <StyledImg fluid={picture.fluid} alt={picture.title} />
    </div>
    <div className="google-rating-container">
      <div className="google-rating">
        <Link
          to={`https://www.google.com/search?q=monbanquet&oq=monbanquet
                   &aqs=chrome..69i57j69i60l3j69i59.1416j0j7&sourceid=chrome&ie=
                   UTF-8#lrd=0x47e66e76a78ab02d:0xdc91ff2723f1b0f1,1,,,`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <GoogleRating small={true} />
        </Link>
      </div>
    </div>
  </StyledPolygonBanner>
)

const StyledImg = styled(Img)`
  position: absolute;
  left: 30%;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
`

const StyledPolygonBanner = styled.section`
  height: 70vh;
  position: relative;
  overflow: hidden;
  .content {
    width: 90%;
    height: 100%;
    clip-path: polygon(0% 0%, 98% 0%, 75.6% 100%, 0 100%);
    display: flex;
    position: relative;
    z-index: 1;
    background-color: ${props => props.color};

    .children-content {
      padding: calc(30 / 100 * 70vh) calc(35 / 100 * 70vh) 0;
      width: 100%;
      z-index: 1;
    }
  }

  .logo-stamp {
    position: absolute;
    top: 64%;
    left: 68%;
    z-index: 2;
  }

  .picture {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    width: 51%;
    align-self: center;
  }

  .google-rating-container {
    position: absolute;
    bottom: 0;
    right: 5%;

    .google-rating {
      width: 200px;
      height: 120px;
      background: #f5f5f5;
      border-radius: 24px 24px 0 0;
      z-index: 1;
    }
  }

  @media (min-width: 2300px) {
    .logo-stamp {
      left: 64%;
    }
  }

  @media (max-width: 1750px) {
    .logo-stamp {
      left: 67%;
    }
  }

  @media (max-width: 1400px) {
    .logo-stamp {
      top: 62%;
      left: 65%;
    }
  }

  @media (max-width: 1275px) {
    .content {
      width: 95%;
    }
    .logo-stamp {
      display: none;
    }
  }

  @media (max-width: 1150px) {
    .content {
      width: 100%;
    }
  }

  @media (max-width: 1100px) {
    height: unset;
    .content {
      clip-path: unset;
    }
    .shapes {
      display: none;
    }
  }

  @media (max-width: ${breakpoints.desktop}px) {
    ${StyledParallaxShape} {
      display: none;
    }
    .content {
      .children-content {
        padding: 120px 0 40px;
        width: unset;
        margin: auto;
      }
    }
  }
`

export default PolygonBanner
export { StyledPolygonBanner }
