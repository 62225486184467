import React from 'react'
import styled from 'styled-components'
import GoogleLogo from '../assets/google_logo.png'
import { breakpoints } from '../theme'

const GoogleStar = ({ small = false }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={small ? '16' : '23.521'}
    height={small ? '16' : '22.42'}
    viewBox="0 0 23.521 22.42"
  >
    <g transform="translate(0 0)">
      <g transform="translate(0)">
        <path
          fill="#FFDE89"
          d="M28.308,16.1l-6.65,4.831,2.54,7.817a.512.512,0,0,1-.789.573l-6.65-4.831L10.11,29.32a.512.512,0,0,1-.788-.573l2.54-7.817L5.212,16.1a.512.512,0,0,1,.3-.927h8.22l2.54-7.817a.512.512,0,0,1,.975,0l2.54,7.817h8.22a.512.512,0,0,1,.3.927"
          transform="translate(-5 -7)"
        />
      </g>
    </g>
  </svg>
)

const getStars = (ratingValue, small) => {
  const stars = []

  for (let i = 0; i < ratingValue; i++) {
    stars.push(<GoogleStar key={i} small={small} />)
  }
  return stars
}

const GoogleRating = ({
  ratingValue = 4.9,
  reviewNumber = 166,
  small = false,
}) => (
  <StyledGoogleRating className={small ? 'small' : ''}>
    <img src={GoogleLogo} alt="google" />
    <div className="rating">
      <p className="rating-value">{ratingValue}</p>
      {getStars(ratingValue, small)}
    </div>
    <p>{reviewNumber} avis Google</p>
  </StyledGoogleRating>
)

const StyledGoogleRating = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    margin-bottom: 8px;
    max-width: 100px;
  }

  .rating {
    display: flex;
    align-items: center;
    margin-bottom: 7px;

    .rating-value {
      font-weight: bold;
      margin-right: 5px;
      font-size: 20px;
    }
  }

  @media (max-width: ${breakpoints.desktop}px) {
    &.small {
      img {
        max-height: 25px;
      }
      .rating-value {
        font-size: 11px;
      }
      p {
        font-size: 8px;
      }
    }
  }
`

export default GoogleRating
export { StyledGoogleRating }
